@import "node_modules/bootstrap-sass/assets/stylesheets/bootstrap";

@import url(http://fonts.googleapis.com/css?family=Open+Sans:300italic,400italic,700italic,300,400,700);

$nav-background: #FFF; 
$heading-color: #777;
$nav-link-hover-color: #FFF;
$brand-color: #555;
$nav-hover-color: #AAA;

body {
	padding-top: 20px;
	padding-bottom: 20px;
	font-family: OpenSans, sans-serif;
}

.center {
	text-align: center;
}

// Node styling
a.node {
	font-family: Garamond;
	text-decoration: none;
	font-size: 24px;
	color: #444;
	
	&:hover {
		color: $nav-hover-color;
	}
	
	&.last-node {
		color: #FFF;
	}
}

textarea.form-control.new-node {
	margin-top: 15px;
}

/* Keep footer at bottom of page */
html,
body {
	height:100%;
}
#wrapper {
	min-height:100%;
	position:relative;
}
#header {
}
#content {
	padding-bottom:100px;   /* Height of the footer element */
}
#footer {
	position: relative;
  	height: 150px;
  	clear:both;
  	padding-top:20px;
	text-align: center;
	vertical-align: middle;
}

#logo {
	padding-top: 20px;
	padding-bottom: 20px;
	text-align: center;
}

#logo img {
	max-width: 90%;
    height: auto;
    width: auto\9; /* ie8 */
    max-height: 100px;
}

.child-row{
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}

.panel-default.hs > .panel-heading {
	background-color: $nav-background;
	color: $heading-color;
}

.navbar-default.hs {
	background-color: $nav-background;
}

.navbar-default.hs .navbar-nav > li > a {
    color: $heading-color;
    &:hover {
    	color: $nav-hover-color;
    }
}

* {
    margin: 0;
}
html, body {
    height: 100%;
}
.container {
    min-height: 100%;
    height: auto !important;
    height: 100%;
    margin: 0 auto -155px; /* the bottom margin is the negative value of the footer's height */
}

nav.footer {
	padding: 15px;
}

a.navbar-brand {
	font-family: Garamond;
	color: $brand-color;
}

button.node-button-left, button.node-button-right {
	width: 47.5%;
}

button.node-button-left {
	margin-right: 1.65%;
}

button.node-button-right {
	margin-left: 1.65%;
}

.upvote-button:hover {
	background-color: #5cb85c;
	color: #eee;
}

.report-button:hover {
	background-color: #f0ad4e;
	color: #eee;
}

.edit-button:hover {
	background-color: #5bc0de;
	color: #eee;
}

.delete-button:hover {
	background-color: #d9534f;
	color: #eee;
}

.paragraph-tag {
    color: #fff;
    text-align: center !important;
}

.paragraph-tag:hover {
    background: #eee none repeat scroll 0 0;
    color: #aaa;
    margin-top: 0;
    text-align: center !important;
}

.align-right {
	text-align: right;
}

table > tbody > tr.notification > td {
	vertical-align: middle;
}